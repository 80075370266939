import { render, staticRenderFns } from "./tupu-folder.vue?vue&type=template&id=12fdb9aa&scoped=true&"
import script from "./tupu-folder.vue?vue&type=script&lang=ts&"
export * from "./tupu-folder.vue?vue&type=script&lang=ts&"
import style0 from "./tupu-folder.vue?vue&type=style&index=0&id=12fdb9aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fdb9aa",
  null
  
)

export default component.exports