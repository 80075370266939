






































import { Component, Vue, Watch } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import LeftNav from "../../components/left-nav.vue";
import NavBox from "../../components/nav-box.vue";
import TopSearch from "../../components/top-search.vue";
import { GetTupu, GetTupu2 } from "@/request/yian";
import CommonTupu from "@/components/common-tupu.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    LeftNav,
    NavBox,
    TopSearch,
    CommonTupu,
  },
})
export default class Name extends Vue {
  private data: any = {
    中医证型: {},
  };
  private tupuData: any = {
    categories: [],
    links: [],
    nodes: [],
  };
  private filters: any = {
    症状: [],
    中医证型: [],
    中医治法: [],
    中医方剂: [],
    中药饮片: [],
    医家: [],
    源文献: [],
    年代: [],
  };
  @Watch("jibing", { immediate: true })
  private jibingChange() {
    if (this.jibing && this.$refs.topSearch) {
      (this.$refs.topSearch as any).reset();
    }
  }
  private get jibing() {
    return this.$store.state.jibing;
  }
  private get type() {
    return this.$store.state.zhuanti;
  }

  private filterChange(e: any) {
    this.filters = e;
  }
  private goSearch(e: any) {
    this.getTupuData();
  }
  private getTupuData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      // bingzhong_id: this.jibing.child.id,
      症状: this.filters["症状"],
      中医证型: this.filters["中医证型"],
      中医治法: this.filters["中医治法"],
      中医方剂: this.filters["中医方剂"],
      中药饮片: this.filters["中药饮片"],
      医家: this.filters["医家"],
      文献: this.filters["源文献"],
      年代: this.filters["年代"],
    };
    if (this.type == "医案病历") {
      params.bingzhong_id = this.jibing.child.id;
      GetTupu(this, params, loading)
        .then((data: any) => {
          loading.close();
          data.nodes.forEach((ele: any, index: any) => {
            if (ele.type === "目标") {
              ele.symbol = "roundRect";
              ele.symbolSize = 25;
            } else if (ele.type === "实体") {
              ele.symbolSize = 20;
            } else {
              ele.symbolSize = 1;
            }
          });
          this.data = data;
          this.tupuData = {
            categories: data.categories,
            links: data.links,
            nodes: data.nodes,
          };
        })
        .catch(() => {
          loading.close();
        });
    } else {
      params["专题类型"] = this.type;
      GetTupu2(this, params, loading)
        .then((data: any) => {
          loading.close();
          data.nodes.forEach((ele: any, index: any) => {
            if (ele.type === "目标") {
              ele.symbol = "roundRect";
              ele.symbolSize = 25;
            } else if (ele.type === "实体") {
              ele.symbolSize = 20;
            } else {
              ele.symbolSize = 1;
            }
          });
          this.data = data;
          this.tupuData = {
            categories: data.categories,
            links: data.links,
            nodes: data.nodes,
          };
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  private getIdErr(data: any) {
    let ids: any = [];
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        ids.push(ele.id);
      });
    }
    return ids;
  }
  private mounted() {
    this.getTupuData();
  }
}
